<template>
  <div>
    <main-top-bar />
    <div
      class="d-flex main-wrapper"
      :class="{ 'toggled' : !showLeftMenu}"
    >
      <main-left-side-bar />

      <main
        id="content"
        class="main-content"
        role="main"
      >
        <div class="container-fluid pt-4 px-4">
          <div class="page-header d-flex justify-content-between align-items-center mb-3 pt-4">
            <h1 class="color-sec">
              Resultados
            </h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                >
                  Resultados
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">

            <Navigator />

            <div class="page-info mb-4" v-if="provas[0] != undefined">
              <div class="row align-items-end" >
                <div class="col-md-6">
                  <h2 class="color-ter fs-24 mb-2">{{ provas[0].aluno.nome }}</h2>
                  <p>{{ provas[0].prova.turma.anoEnsino.titulo }} <strong style="color: black;">-</strong> {{ provas[0].prova.turma.titulo }}</p>
                </div>

                <div class="col-md-6 text-right">
                </div>
              </div>
            </div>

            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          type="search"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-3 d-flex justify-content-md-end">
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button class="btn btn-classic">
                        <i class="fa fa-print" /> IMPRIMIR
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="block__classic table table__results table-striped table-borderless table-hover text-center"
              >
                <thead>
                  <tr>
                    <th class="text-left pl-4" scope="col">Prova</th>
                    <th scope="col">Data</th>
                    <th scope="col">Nota</th>
                    <th scope="col">Desempenho</th>
                    <th scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item) in this.provas"
                    :key="item.id"
                  >
                    <td 
                      class="text-left pl-4"
                      v-html="item.prova.titulo"
                    >
                    <td class="text-center">
                      {{ item.prova.dataHoraInicio | formatDate }}
                    </td>
                    <td class="text-center" v-if="item.finalizadaEm">
                      <strong>{{ item.notaAlcancada }}</strong>
                    </td>
                    <td class="text-center" v-else>-</td>
                    <td class="text-left" v-if="item.finalizadaEm">
                      <div class="progress-info__content">
                        <div class="progress h-8">
                          <div class="progress-bar bg-success" role="progressbar" :style="`width: ` + percentualAcerto(item) + `%`" :aria-valuenow="percentualAcerto(item)" aria-valuemin="0" aria-valuemax="100"></div>
                          <!-- <div class="progress-bar bg-warning" role="progressbar" style="width: 15%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div> -->
                          <div class="progress-bar bg-error" role="progressbar" :style="`width: ` + percentualErro(item) + `%`" :aria-valuenow="percentualErro(item)" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span class="icon-tooltip" data-toggle="tooltip" data-placement="top" title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."><i class="help"></i></span>
                      </div>
                    </td>
                    <td class="text-center" v-else>-</td>
                    <td v-if="item.finalizadaEm">
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="showResult(item.prova.id, item.aluno.id)"
                      >
                        <i class="far fa-eye" />
                      </button>               
                    </td>
                    <td class="text-center" v-else>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

import MainLeftSideBar from "@/components/menu/MainLeftSideBar"
import MainTopBar from "@/components/menu/MainTopBar";
import moment from 'moment';
import Navigator from '../../../components/resultados/ResultadosNavigator.vue';

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    Navigator
  },
  data() {
    return {
      showLeftMenu: true,
      provas: []
    }
  },
  created() {
    this.$root.$on('Dashboard::toggleLeftMenu', () => { this.showLeftMenu = !this.showLeftMenu });
    this.loadProvas();
  },
  filters:{     
      formatDate(value) {
        if (value) {
          return moment(String(value)).format('DD/MM/YYYY hh:mm')        
        }else{
          return '-'
        }
      }
  },
  methods: {
    showResult(idprova, idaluno){
      this.$router.push("/resultados-detalhe-prova-aluno/"+idprova+"/aluno/"+idaluno);
    },
    goDetalheProvaGeral(idprova){
      this.$router.push("/resultados-detalhe-prova-geral/"+idprova);
    },
    loadProvas(){
      this.$api
        .get("lista-provas-aluno/"+this.$route.params.idaluno)
        .then((response) => {
          this.provas = response.data;
          console.log(response.data)
          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    percentualAcerto(item){
      let percentual = item.notaAlcancada / item.prova.notaMaxima * 100;
      percentual = Math.ceil(percentual);
      return percentual;
    },
    percentualErro(item){
      let percentualErro = 100 - this.percentualAcerto(item);
      return percentualErro
    }
  },
}

</script>

<style>

</style>